import { Box, Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { FunctionComponent } from 'react';
import IFrameComponent from '../i-frame-component';

const WIDTH = '180px';
const HEIGHT = '400px';
const HEIGHT_SM = '220px';

const text = {
  title: 'Alerts',
  button: 'View In Intersight',
  buttonUrl: 'https://intersight.com/an/hyperflex/clusters',
  clusterAlertsTitle: 'Cluster Overview',
  clusterAlerts:
    'https://blueocean-dev-vm.ignw.io/monitoring/d-solo/jXRlkASMz/cluster-alerts?orgId=1&var-clusterName=Pod4-HX01&panelId=8',
  cpuUsageTitle: 'CPU Usage',
  cpuUsage:
    'https://blueocean-dev-vm.ignw.io/monitoring/d-solo/jXRlkASMz/cluster-alerts?orgId=1&var-clusterName=Pod4-HX01&panelId=4',
  memoryUsageTitle: 'Memory Usage',
  memoryUsage:
    'https://blueocean-dev-vm.ignw.io/monitoring/d-solo/jXRlkASMz/cluster-alerts?orgId=1&var-clusterName=Pod4-HX01&panelId=2',
  storageUsageTitle: 'Storage Usage',
  storageUsage:
    'https://blueocean-dev-vm.ignw.io/monitoring/d-solo/jXRlkASMz/cluster-alerts?orgId=1&var-clusterName=Pod4-HX01&panelId=6',
};

const ClusterAlertsViz: FunctionComponent<{}> = () => {
  const handleClick = (url: string) => {
    const win = window.open(url, '_blank');
    win && win.focus();
  };

  return (
    <Box flex={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify={'space-between'}>
            {/* <Typography variant={'h5'}>{text.title}</Typography> */}
            <Box mb={1}>
              <Grid container direction={'row'} spacing={1}>
                <Grid item>
                  <NotificationsIcon />
                </Grid>
                <Grid item>
                  <Typography color="secondary" variant="h5">
                    {text.title}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Button
              color="secondary"
              endIcon={<OpenInNewIcon />}
              onClick={() => {
                handleClick(text.buttonUrl);
              }}
            >
              {text.button}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT_SM}
            title={text.clusterAlertsTitle}
            src={text.clusterAlerts}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.cpuUsageTitle}
            src={text.cpuUsage}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.memoryUsageTitle}
            src={text.memoryUsage}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.storageUsageTitle}
            src={text.storageUsage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClusterAlertsViz;
