import Box from '@material-ui/core/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ClusterAlertsViz from '../../components/cluster-alerts';
import Layout from '../../components/layout/Layout';
import SEO from '../../components/seo/SEO';

export const ClusterAlertsOverViewPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t('alerts.title')} />
      <Box flex={1} padding={2}>
        <ClusterAlertsViz />
      </Box>
    </Layout>
  );
};

export default ClusterAlertsOverViewPage;
